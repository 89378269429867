<template>
  <div>
    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Role Details</h5>

            <div class="col-md-12 mb-2">
              <label for="roleName" class="form-label">Role Name</label>
              <input type="text" class="form-control" id="roleName" v-model="role.name">
            </div>

            <div class="row">
              <div class="col-md-12">
                <input type="button" style="float: right;" class="btn btn-light px-4" value="Save Changes">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Role Permissions</h5>

            <div class="row">
              <div class="col-md-4" v-for="group in permissionGroups" :key="'group-' + group.id">
                <p style="font-weight: bold;">{{group.name}}</p>
                <table id="example" class="table table-hover" style="width:100%">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="permission in group.permissions" :key="permission.id">
                      <td>{{permission.id}}</td>
                      <td>{{permission.name}}</td>
                      <td>
                        <button v-if="permission.enabled" class="btn btn-sm btn-danger" @click="removePermission(permission)">Remove</button>
                        <button v-else class="btn btn-sm btn-success" @click="assignPermission(permission)">Add</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'admin.management.roles.view',
  props: ['id'],
  data(){
    return {
      role: {
        id: -1,
        name: "",
        permissions: []
      },
      permissionGroups: [],
    }
  },
  mounted(){
    this.loadRole();
  },
  methods: {
    loadRole(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/roles/${this.id}`)
      .then(response => {
        this.role = response.data.role;
        this.loadPermissionGroups();
      })
      .catch(error => {
        this.$error("Failed loading role.", error);
      });
    },
    loadPermissionGroups(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/permissiongroups/list`)
      .then(response => {
        this.permissionGroups = response.data.permissionGroups;
        this.permissionGroups.forEach((pg) => {
          pg.permissions.forEach((p) => {
            let roleHasPermission = this.role.permissions.filter((permission) => {
              return permission.id === p.id;
            }).length;
            p.enabled = roleHasPermission > 0;
          })
        });
      })
      .catch(error => {
        this.$error("Failed loading permission groups.", error);
      })
    },
    assignPermission(permission){
      axios.put(`${process.env.VUE_APP_API_URL}/v1/roles/${this.id}/permissions`, {
        permission_id: permission.id
      })
      .then(() => {
        permission.enabled = true;
      })
      .catch(error => {
        this.$error("Failed to assign permission.", error);
      })
    },
    removePermission(permission){
      axios.delete(`${process.env.VUE_APP_API_URL}/v1/roles/${this.id}/permissions`, {
        data: {
          permission_id: permission.id
        }
      })
      .then(() => {
        permission.enabled = false;
      })
      .catch(error => {
        this.$error("Failed to remove permission.", error);
      })
    }
  }
}
</script>